export const api = {
  addImage: '/CustomerService/upload/addImage', //上传图片
  login: '/CustomerService/UserProfile/login', // 密码登录
  codeLogin: '/CustomerService/UserProfile/codeLogin', // 验证码登录
  all_users: '/CustomerService/InstantSession/all_users', // 获取客服列表
  setBusy: '/CustomerService/InstantSession/setBusy', //设置客服状态
  systemSendSms: '/CustomerService/UserProfile/systemSendSms', // 获取验证码
  forgetPwd: '/CustomerService/UserProfile/forgetPwd', //忘记密码
  logout: '/CustomerService/UserProfile/logout', // 客服退出登录
  getGoodsClass: '/CustomerService/Goods/classifyList', // 获取商品分类列表
  getGoodsList: '/CustomerService/Goods/goodsList', //获取分类的商品列表
  getProblem: '/CustomerService/CustomerQuestion/list', // 获取常见问题列表
  addProblem: '/CustomerService/CustomerQuestion/add', // 添加常见问题列表
  editProblem: '/CustomerService/CustomerQuestion/edit', // 编辑常见问题
  delProblem: '/CustomerService/CustomerQuestion/del', // 删除常见问题
  sortQuestion: '/CustomerService/CustomerQuestion/sortQuestion', // 常见问题排序
  getAutomaticReply: '/CustomerService/AutomaticReply/getAutomaticReply', // 获取自动回复设置
  setAutomaticReply: '/CustomerService/AutomaticReply/setAutomaticReply', //设置自动回复设置
  getWxCard: '/CustomerService/CustomerCard/getCard', // 获取客服微信名片
  setCard: '/CustomerService/CustomerCard/setCard', // 设置微信名片
  already_users: '/CustomerService/InstantSession/already_users', // 待接入客户
  accessUser: '/CustomerService/InstantSession/accessUser', // 接入用户
  getSelfServiceMenu: '/CustomerService/CustomerSelfServiceMenu/getSelfServiceMenu', //获取自定义菜单设置
  getCostomerMenu: '/CustomerService/CustomerSelfServiceMenu/getCostomerMenu', // 获取自定义菜单列表
  setSelfServiceMenu: '/CustomerService/CustomerSelfServiceMenu/setSelfServiceMenu', // 设置自定义菜单
  couponList: '/CustomerService/Coupon/list', // 获取优惠券列表
  getCouponInfo: '/CustomerService/Coupon/getCouponInfo', // 获取优惠券详情
  getChatRecord: '/CustomerService/InstantSession/getChatRecord' // 获取聊天记录
}
