// 菜单图片地址
export const menuImgUrl = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/customerSystem/menuImg/'

// 其他图片地址
export const imgUrl = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/customerSystem/'

// 正式线地址
export const baseurl = 'https://testdigitalize.cnweisou.net'

// 测试线地址
// export const baseurl = 'https://digitization.5g-center.com'

// websocket地址 测试线
// export const socketUrl = 'wss://digitizationsocket.5g-center.com?token='

//websocket地址 正式线
export const socketUrl = 'wss://digitalizesocket.cnweisou.net?token='
