import axios from 'axios'
import { baseurl } from './baseUrl'
import router from '../router'
import { ElMessage } from 'element-plus'
let path = ''
let qs = require('qs')
//开发环境
if (process.env.NODE_ENV == 'development') {
  path = baseurl
}
//生产环境
if (process.env.NODE_ENV == 'production') {
  path = baseurl
}

axios.defaults.timeout = 20000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem('token')
    if (token) config.headers['access-token'] = token
    config.url = path + config.url
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
//响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data.code == 10005) {
      sessionStorage.clear()
      router.push('/login')
    }
    return response.data
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误'
          break

        case 401:
          err.message = '未授权，请登录'
          break

        case 403:
          err.message = '拒绝访问'
          break

        case 404:
          err.message = '请求地址出错'
          break

        case 408:
          err.message = '请求超时'
          break

        case 500:
          err.message = '服务器内部错误'
          break

        case 501:
          err.message = '服务未实现'
          break

        case 502:
          err.message = '网关错误'
          break

        case 503:
          err.message = '服务不可用'
          break

        case 504:
          err.message = '网关超时'
          break

        case 505:
          err.message = 'HTTP版本不受支持'
          break

        default:
      }
    }
    ElMessage.error(err.message)
    return Promise.reject(err)
  }
)

//get请求
export function get(url, data = {}) {
  let params = {
    params: data
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
//post请求
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(data))
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export function uploadFile(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
